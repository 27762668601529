import React from 'react'

import './SavedDevider.css'

function SavedDevider() {
  return (
    <div className="devider"></div>
  )
}

export default SavedDevider